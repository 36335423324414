var form = (function($) {
    'use strict';
  
    function init() {
      autoFocusForms();
      bindContactFormProtection();
    }

    function autoFocusForms(){
      const element = document.querySelector('form.autofocus');
      if(element !== undefined && element !== null){
        element.scrollIntoView();
      }
    }

    function bindContactFormProtection(){
      $('.js-contact-color-select').val('green');
    }
  
    return {
      init: init
    };
  
  })(jQuery);
  
  export default form;