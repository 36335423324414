var locale = (function($) {
  'use strict';
	function init() {
	//	bindChangeLocaleEvent();
	}

	function getLocale(){
		let locale = $('#site_locale').data('locale') || 'bg';

		return locale;
	}

	// function bindChangeLocaleEvent() {
	// 	$('.js-change-locale').click(function(e){
	// 		e.preventDefault();
	// 		let selectedLocale = $(this).data('locale');
	// 		let currentLocale = getLocale();
	// 		if(selectedLocale == currentLocale){
	// 			return;
	// 		}

	// 		// get the new url from the meta tags; if there is no alternate tag with the selected locale, redirect to the home page
	// 		let alternateLink = $('link[rel="alternate"][hreflang="' + selectedLocale +'"]');
	// 		let redirectLink = alternateLink.attr('href') ?? '/' + selectedLocale;

	// 		window.location.href = redirectLink;          
	// 	})
	// }

  return {
    init: init,
    getLocale: getLocale
  };

})(jQuery);

export default locale;