var slider = (function($) {
    'use strict';
  
    function init() {
      $('.owl-one').owlCarousel({
          nav: true,
          loop:true,
          margin: 20,
          center: true,
          autoplay: true,
          autoplayTimeout:3500,
          autoplayHoverPause:true,
          dots: false,
          responsive: {
              0: {
                  items: 1,
                  nav: true
              },
              1024: {
                  items: 2,
                  nav: true
              }
          }
      });
      $('.play').on('click',function(){
          owl.trigger('play.owl.autoplay',[1000])
      })
      $('.stop').on('click',function(){
          owl.trigger('stop.owl.autoplay')
      });
  
      $('.owl-two').owlCarousel({
          items:4,
          loop:true,
          margin: 10,
          autoplay:true,
          autoplayTimeout:3500,
          autoplayHoverPause:true,
          dots: false
      });
      $('.play').on('click',function(){
          owl.trigger('play.owl.autoplay',[1000])
      })
      $('.stop').on('click',function(){
          owl.trigger('stop.owl.autoplay')
      });
    }
  
    function destroy(){
       $(".owl-one").owlCarousel('destroy');
       $(".owl-two").owlCarousel('destroy');
    }
  
    return {
      init: init,
      destroy: destroy
    };
  
  })(jQuery);
  
  export default slider;