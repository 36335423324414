import TweenMax from "gsap"
import { WOW } from './wow'

document.addEventListener('turbolinks:load', function(event) {

  "use strict";

  function dynamicCurrentMenuClass(selector) {
    let FileName = window.location.href.split('/').reverse()[0];

    selector.find('li').each(function() {
      let anchor = $(this).find('a');
      if ($(anchor).attr('href') == FileName) {
        $(this).addClass('current');
      }
    });
    // If any li has .current element add class
    selector.children('li').each(function() {
      if ($(this).find('.current').length) {
        $(this).addClass('current');
      }
    });
    // If no file name return 
    if ('' == FileName) {
      selector.find('li').eq(0).addClass('current');
    }
  }

  // Dynamic current class        
  let mainNavUL = $('.main-menu').find('.navigation');
  dynamicCurrentMenuClass(mainNavUL);

  // Update header style and scroll to top
  function headerStyle() {
    if ($('.main-header').length) {
      var windowpos = $(window).scrollTop();
      var siteHeader = $('.main-header');
      var scrollLink = $('.scroll-to-top');
      var sticky_header = $('.main-header .sticky-header');
      if (windowpos > 100) {
        siteHeader.addClass('fixed-header');
        sticky_header.addClass("animated slideInDown");
        scrollLink.fadeIn(300);
      } else {
        siteHeader.removeClass('fixed-header');
        sticky_header.removeClass("animated slideInDown");
        scrollLink.fadeOut(300);
      }
    }
  }
  headerStyle();

  // Hidden sidebar
  if ($('.hidden-sidebar').length) {

    var animButton = $(".sidemenu-nav-toggler"),
      hiddenBar = $(".hidden-sidebar"),
      navOverlay = $(".nav-overlay"),
      hiddenBarClose = $(".hidden-sidebar-close");

    function showMenu() {
      TweenMax.to(hiddenBar, 0.6, {
        force3D: false,
        left: "0",
        ease: Expo.easeInOut
      });
      hiddenBar.removeClass("close-sidebar");
      navOverlay.fadeIn(500);
    }

    function hideMenu() {
      TweenMax.to(hiddenBar, 0.6, {
        force3D: false,
        left: "-480px",
        ease: Expo.easeInOut
      });
      hiddenBar.addClass("close-sidebar");
      navOverlay.fadeOut(500);
    }
    animButton.on("click", function() {
      if (hiddenBar.hasClass("close-sidebar")) showMenu();
      else hideMenu();
    });
    navOverlay.on("click", function() {
      hideMenu();
    });
    hiddenBarClose.on("click", function() {
      hideMenu();
    });
  }

  if ($('.nav-overlay').length) {
    // /cursor/
    var cursor = $(".nav-overlay .cursor"),
      follower = $(".nav-overlay .cursor-follower");

    var posX = 0,
      posY = 0;

    var mouseX = 0,
      mouseY = 0;

    TweenMax.to({}, 0.016, {
      repeat: -1,
      onRepeat: function() {
        posX += (mouseX - posX) / 9;
        posY += (mouseY - posY) / 9;

        TweenMax.set(follower, {
          css: {
            left: posX - 22,
            top: posY - 22
          }
        });

        TweenMax.set(cursor, {
          css: {
            left: mouseX,
            top: mouseY
          }
        });

      }
    });

    $(document).on("mousemove", function(e) {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      mouseX = e.pageX;
      mouseY = e.pageY - scrollTop;
    });
    $("button, a").on("mouseenter", function() {
      cursor.addClass("active");
      follower.addClass("active");
    });
    $("button, a").on("mouseleave", function() {
      cursor.removeClass("active");
      follower.removeClass("active");
    });
    $(".nav-overlay").on("mouseenter", function() {
      cursor.addClass("close-cursor");
      follower.addClass("close-cursor");
    });
    $(".nav-overlay").on("mouseleave", function() {
      cursor.removeClass("close-cursor");
      follower.removeClass("close-cursor");
    });
  }

  // Navbar main pages content
  var mainPages = $('.main-header .nav-outer .main-menu').html();
  var profileLinks = $('.user').html();

  // Add content to the sticky header
  if (!$('.sticky-header .main-menu').children().length > 0) {
    $('.sticky-header .main-menu').append(mainPages);
  }

  //  Show/hide mobile menu 
  if ($('.mobile-menu').length) {

    // Add content to mobile menu
    if (!$('.mobile-menu .menu-box .menu-outer').children().length > 0) {
      $('.mobile-menu .menu-box .menu-outer').prepend(profileLinks);
      $('.mobile-menu .menu-box .menu-outer').append(mainPages);
      // Submenu dropdown toggle
      $('.main-header .navigation li.dropdown').append('<div class="dropdown-btn"><span class="fa fa-angle-right"></span></div>');
    }   

    // Dropdown button
    $('.mobile-menu li.dropdown .dropdown-btn').on('click', function() {
      $(this).toggleClass('open');
      $(this).prev('ul').slideToggle(500);
    });

    // Menu toggle button
    $('.mobile-nav-toggler').on('click', function() {
      $('body').addClass('mobile-menu-visible');
    });

    // Menu toggle button
    $('.mobile-menu .menu-backdrop,.mobile-menu .close-btn,.scroll-nav li a').on('click', function() {
      $('body').removeClass('mobile-menu-visible');
    });
  }

  // Sidemenu nav hide/show
  if ($('.side-menu').length) {

    // Dropdown button
    $('.side-menu li.dropdown .dropdown-btn').on('click', function() {
      $(this).toggleClass('open');
      $(this).prev('ul').slideToggle(500);
    });

    $('body').addClass('side-menu-visible');

    // Menu toggle button
    $('.side-nav-toggler').on('click', function() {
      $('body').addClass('side-menu-visible');
    });

    // Menu toggle button
    $('.side-menu .side-menu-resize').on('click', function() {
      $('body').toggleClass('side-menu-visible');
    });

    // Menu toggle button
    $('.main-header .mobile-nav-toggler-two').on('click', function() {
      $('body').addClass('side-menu-visible-s2');
    });

    // Menu overlay
    $('.main-header .side-menu-overlay').on('click', function() {
      $('body').removeClass('side-menu-visible-s2');
    });
  }

  // Search popup
  if ($('#search-popup').length) {

    // Show popup
    $('.search-toggler').on('click', function() {
      $('#search-popup').addClass('popup-visible');
    });

    $(document).on('keydown', function(e) {
      if (e.keyCode === 27) {
        $('#search-popup').removeClass('popup-visible');
      }
    });

    // Hide Popup
    $('.close-search,.search-popup .overlay-layer').on('click', function() {
      $('#search-popup').removeClass('popup-visible');
    });
  }

  // Universal Code for All Owl Carousel Sliders
  if ($('.theme_carousel').length) {
    $(".theme_carousel").each(function(index) {
      var $owlAttr = {},
        $extraAttr = $(this).data("options");
      $.extend($owlAttr, $extraAttr);
      $(this).owlCarousel($owlAttr);
    });
  }

  // Fact Counter + Text Count
  if ($('.count-box').length) {
    $('.count-box').appear(function() {

      var $t = $(this),
        n = $t.find(".count-text").attr("data-stop"),
        r = parseInt($t.find(".count-text").attr("data-speed"), 10);

      if (!$t.hasClass("counted")) {
        $t.addClass("counted");
        $({
          countNum: $t.find(".count-text").text()
        }).animate({
          countNum: n
        }, {
          duration: r,
          easing: "linear",
          step: function() {
            $t.find(".count-text").text(Math.floor(this.countNum));
          },
          complete: function() {
            $t.find(".count-text").text(this.countNum);
          }
        });
      }
    }, {
      accY: 0
    });
  }

  // Fancybox
  if ($('.lightbox-image').length) {
    $('.lightbox-image').fancybox({
      openEffect: 'fade',
      closeEffect: 'fade',
      keyboard: true,
      arrows: true,
      infobar: true,
      protect: true, // disabled right mouse button
      preventCaptionOverlap: true,
      buttons: [
        "zoom",
        "share",
        "slideShow",
        "fullScreen",
        //"download",
        "close"
      ]
    });
  }

  // Testimonial 
  if ($('.testimonial-carousel').length) {
    var testimonialThumb = new Swiper('.testimonial-thumbs', {
      preloadImages: false,
      loop: true,
      speed: 2400,
      slidesPerView: 3,
      centeredSlides: true,
      spaceBetween: 0,
      nav: false,
      dots: false,
      effect: "slide",
    });
  }

  // Scroll to a top/specific div
  // if ($('.scroll-to-target').length) {
  //   $(".scroll-to-target").on('click', function() {
  //     var target = $(this).attr('data-target');
  //     // animate
  //     $('html, body').animate({
  //       scrollTop: $(target).offset().top
  //     }, 1500);
  //   });
  // }

  // Elements Animation
  if ($('.wow').length) {
    var wow = new WOW({
      boxClass: 'wow', // animated element css class (default is wow)
      animateClass: 'animated', // animation css class (default is animated)
      offset: 0, // distance to the element when triggering the animation (default is 0)
      mobile: true, // trigger animations on mobile devices (default is true)
      live: false // act on asynchronously loaded content (default is true)
    });
    wow.init();
    wow.sync();
  }

  // When document is scrolled
  $(window).on('scroll', function() {
    headerStyle();
  });

  // dynamically adjust main class minimum height
  $(window).on('resize', function() {
    let headerHeight = $('.main-header').outerHeight();
    let footerMainHeight = $('.main-footer').outerHeight();
    let footerBottomHeight = $('.footer-bottom').outerHeight();

    let mainContainerHeight = ($(window).outerHeight() - (headerHeight + footerMainHeight + footerBottomHeight));

    $('.main').css('min-height', mainContainerHeight);

    // set the empty result container same width as search field 
    $(".search-results").css({
    'width': ($(".search-field").outerWidth() + 'px')
    });
  });

  $(window).trigger('resize');

  // disable zoom on input click
  const addMaximumScaleToMetaViewport = () => {
    const el = document.querySelector('meta[name=viewport]');

    if (el !== null) {
      let content = el.getAttribute('content');
      let re = /maximum\-scale=[0-9\.]+/g;

      if (re.test(content)) {
        content = content.replace(re, 'maximum-scale=1.0');
      } else {
        content = [content, 'maximum-scale=1.0'].join(', ')
      }

      el.setAttribute('content', content);
    }
  };

  const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

  const checkIsIOS = () =>
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (checkIsIOS()) {
    disableIosTextFieldZoom();
  }

  // Facebook share button
  let fbButton = $('.fb-share-button');
  let url = $(location).attr('href');

  fbButton.on('click', function() {
      window.open('https://www.facebook.com/sharer/sharer.php?u=' + url,
          'facebook-share-dialog',
          'width=800,height=600'
      );
      return false;
  });

});
